import Head from "next/head";
import "../styles/style.css";

// eslint-disable-next-line react/prop-types
const App = ({ Component, pageProps }) => (
  <>
    <Head>
      <title>Lancaster PA Custom Woodworking | Wooden Peanut</title>
      <meta name="description" content="Custom woodworking based in Lancaster, PA" />
      <link rel="shortcut icon" href="/favicon.png" />
    </Head>
    <Component {...pageProps} />
  </>
);

export default App;
